import React from 'react'
import { Link } from 'react-router-dom'

function Rabotage() {
    return (
        <div className='rabotage'>
            <h1><Link to="/produits">&#x2B05;</Link>Rabotage et Traitement</h1>
            <fleche className='fleche-div'>
                <Link to="/produits" className='fleche'>⬅</Link>
            </fleche>
            <div>
                <div>
                    <div>
                        <h2>Rabotage</h2>
                        <table>
                            <tbody>
                                <tr>
                                    <td>Service</td>
                                    <td>Prix en Euros TTC au m3</td>
                                </tr>
                                <tr>
                                    <td>Rabotage</td>
                                    <td>78€</td>
                                </tr>
                                <tr>
                                    <td>Calibrage</td>
                                    <td>72€</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>Pour tous produits rabotés prévoir <span>5 mm de perte</span> sur les deux côtés
                            Ex : 12 x 24 cm = 11,5 x 23,5.
                        </p>
                        <p>Pour éviter une diminution des sections prévoyez une surcôte de
                            fabrication d’environ 5 mm </p>
                        <p>Ex : 12.5 x 24.5 pour avoir une côte de 12 x 24 après rabotage.</p>
                    </div>
                    <div>
                        <img src="./images/rabotage/rabotage (1).webp" alt="" />
                        <p>&#x2B07;</p>
                        <img src="../images/rabotage/rabotage (2).webp" alt="" />
                    </div>
                </div>
                <div>
                    <div>
                        <h2>Traitement</h2>
                        <table>
                            <tbody>
                                <tr>
                                    <td>Service</td>
                                    <td>Prix en Euros TTC au m3</td>
                                </tr>
                                <tr>
                                    <td>Traitement</td>
                                    <td>49.2€</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            Ce traitement est de <span>classe 2</span>, une fonction insecticide et fongicide. Le
                            traitement est réalisé par trempage, le bois est alors imprégné sur la
                            périphérie (trace de couleur jaune).
                        </p>
                    </div>
                    <div>
                        <img src="./images/rabotage/traitement (1).webp" alt="" />
                        <p>&#x2B07;</p>
                        <img src="./images/rabotage/traitement (2).webp" alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Rabotage