import React from 'react'
import { Link } from 'react-router-dom'

function BoisDeCharpente() {
    const backToTop = () => {
      window.scrollTo({ top: 0 })
  }
  return (
    <div className='charpente'>
        
        <h1><Link to="/produits">&#x2B05;</Link>Bois de Charpente</h1>
        <fleche className='fleche-div'>
                <Link to="/produits" className='fleche'>⬅</Link>
            </fleche>
        <div>
            <Link to='/bois-sur-mesure' onClick={backToTop}><h2>Bois sur mesure</h2></Link>
            <Link to='/bois-de-structure' onClick={backToTop}><h2>Bois de structure</h2></Link>
            <Link to='/bois-d-ossature' onClick={backToTop}><h2>Bois d'ossature</h2></Link>
            <Link to='/planche-de-coffrage-volige' onClick={backToTop}><h2>Planche de coffrage-volige</h2></Link>
            <Link to='/planche-de-charpente'onClick={backToTop}><h2>Planche de charpente</h2></Link>
        </div>
    </div>
  )
}

export default BoisDeCharpente