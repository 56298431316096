import React from 'react'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom';

function Hero() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000, // Changement d'image toutes les 5 secondes
  };
  return (
    <div className="hero">
      <Slider {...settings}>
        <div>
          <img src="./images/carrousel/photo-1.webp" alt="Image 1" />
        </div>
        <div>
          <img src="./images/carrousel/photo-2.webp" alt="Image 2" />
        </div>
        <div>
          <img src="./images/carrousel/photo-3.webp" alt="Image 3" />
        </div>
        <div>
          <img src="./images/carrousel/photo-4.webp" alt="Image 4" />
        </div>
        <div>
          <img src="./images/carrousel/photo-5.webp" alt="Image 5" />
        </div>
        <div>
          <img src="./images/carrousel/photo-6.webp" alt="Image 6" />
        </div>
      </Slider>
      <div className='news'>
        <h1>Fermeture hivernale</h1>
        <p>du 20 Décembre 2024 au 2 Janvier 2025</p>
      </div>
    </div>
  )
}

export default Hero