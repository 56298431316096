import React from 'react'
import { Link } from 'react-router-dom'

function SurMesure() {
    return (
        <div className='surmesure'>
            <h1><Link to="/bois-de-charpente">&#x2B05;</Link>Bois sur Mesure</h1>
            <fleche className='fleche-div'>
                <Link to="/bois-de-charpente" className='fleche'>⬅</Link>
            </fleche>
            <div>
                <table>
                    <thead>
                        <tr>
                            <th colSpan="1" rowSpan="2" className='border-none-table'>
                                LONGUEUR EN ML
                            </th>
                            <th colSpan="1" rowSpan="2">
                                SECTION
                            </th>
                            <th colSpan="2" className='border-none-table'>
                                TARIF EN EUROS TTC AU M3
                            </th>
                        </tr>
                        <tr>
                            <th>
                                SAPIN/PIN
                            </th>
                            <th>
                                DOUGLAS
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className='border-left-table'>2 à 5,5</td>
                            <td rowspan="6">Section Standard ou Débit sur liste</td>
                            <td>420</td>
                            <td>540</td>
                        </tr>

                        <tr>
                            <td className='border-left-table'>5,6 à 6,5</td>
                            <td>444</td>
                            <td>564</td>
                        </tr>

                        <tr>
                            <td className='border-left-table'>6,6 à 7,5</td>
                            <td>468</td>
                            <td>588</td>
                        </tr>

                        <tr>
                            <td className='border-left-table'>7,6 à 8,5</td>
                            <td>492</td>
                            <td>612</td>
                        </tr>

                        <tr>
                            <td className='border-left-table'>8,6 à 9,5</td>
                            <td>516</td>
                            <td>636</td>
                        </tr>

                        <tr>
                            <td className='border-none-table'>9,6 à 12</td>
                            <td>540</td>
                            <td className='border-none-table'>660</td>
                        </tr>
                    </tbody>
                </table>
                <div>
                    <div>
                        <p><span>Qualité/Finition</span> : C18 / Brut</p>
                        <p>Les produits peuvent être rabotés ou traités à la demande.</p>
                        <p>Les sections standard disponible pour chaque longueur : 10x18 10x20 10x22 12x24 15x30</p>
                    </div>
                    <img src="./images/boisSurMesure.webp" alt="" />
                </div>

            </div>
        </div>
    )
}

export default SurMesure