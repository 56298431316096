import React from 'react'
import { Link } from 'react-router-dom'

function Presentation() {
  return (
    <div className='presentation'>
      <h1><Link to="/">&#x2B05;</Link>Présentation</h1>
      <fleche className='fleche-div'>
        <Link to="/" className='fleche'>⬅</Link>
      </fleche>
      <section>
        <div>
          <div>
            <h2>Actualités</h2>
            <p>La scierie se diversifie dans l'aménagement extérieur avec du Pin Laricio Thermotraité (Classe 4).</p>
          </div>
          <img src="./images/histoire/histoire (1).webp" alt="" />
        </div>
        <div>
          <img src="./images/histoire/histoire (2).webp" alt="" />
          <div>
            <h2>Court historique</h2>
            <p>La Scierie existe depuis quatre générations. Elle fût créée au début des années 1950
              sur le plateau ardéchois par Auguste Charre, grand-père d'André Charre actuel
              gérant. Suite aux hivers rigoureux des années 1970, la scierie déménage à
              Lavilledieu le 1
              er
              Janvier 1974. André prend la succession de son père en 1978 avec
              son épouse Bernadette. Aujourd'hui l'ont rejoint ses deux fils Jérôme et Cyril avec qui
              il dirige la Scierie André Charre et Fils.</p>
          </div>
        </div>
        <div>
          <div>
            <h2>Secteur d'activité</h2>
            <h3>BOIS DE CHARPENTE</h3>
            <p>- Charpente sur mesure avec débit sur liste ou dimension standard. <br />
              - Planche de coffrage, Volige, Planche de charpente, Planche de dosse. <br />
              - Bois de structure : Plateaux maçon, Bastaing, Chevron, Lambourde, Liteau, Bois
              d'ossature</p>
            <h3>AMENAGEMENT INTERIEUR ET EXTERIEUR</h3>
            <p>- Intérieur : Plancher (≠ Parquets), Bardage + Bois Raboté <br />
              - Extérieur : Lames Terrasse et piscine, Bardage, Lames barrière + Structure en Bois</p>
            <h3>RABOTAGE ET TRAITEMENT</h3>
            <p>- Rabotage 4 faces pour le bois de charpente. <br />
              - Traitement classe 2 par trempage.</p>
          </div>
          <img src="./images/histoire/histoire (5).webp" alt="" />
        </div>

      </section>
    </div>

  )
}

export default Presentation