import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
    return (
        <div className='footer'>
            <div className='footer-container'>
                <div className='coordo'>
                    <h2>Coordonnées</h2>
                    <div>
                        <p>90 chemin des Roquelles</p>
                        <p>Quartier Les Persèdes</p>
                        <p>07170 Lavilledieu</p>
                    </div>
                    <a href="https://www.google.com/maps/place/André+Charre+et+Fils+SARL/@44.5891721,4.4456317,17z/data=!3m1!4b1!4m15!1m8!3m7!1s0x12b51f0f024897c7:0xb75e53de7abff856!2sAndré+Charre+et+Fils+SARL!8m2!3d44.5891683!4d4.448212!10e1!16s%2Fg%2F1vk4r0sz!3m5!1s0x12b51f0f024897c7:0xb75e53de7abff856!8m2!3d44.5891683!4d4.448212!16s%2Fg%2F1vk4r0sz?entry=ttu" className='itineraire'>Voir l'itinéraire</a>
                    <a href="tel:+33475948051">Tél: 04 75 94 80 51</a>
                    <a href="mailto:scierie.acharreetfils@wanadoo.fr">E-mail: scierie.acharreetfils@wanadoo.fr</a>
                </div>
                <div className='button-footer desktop'>
                    <Link to="/devis" className='devis' onClick={()=> (window.scrollTo({ top: 0, behavior: 'smooth' }))}>Demander un devis</Link>
                    <Link to="/pdf/Catalogue Produits 2024.pdf" target='_blank' rel='noreferrer' className='catalogue'>Consulter le catalogue 2024</Link>
                </div>
                <div className='horaire'>
                    <h2>Horaires d'ouverture :</h2>
                    <p>Le lundi: 9h-12h et 13h30-17h30</p>
                    <p>Du mardi au jeudi: 8h00-12h00 13h30-17h30</p>
                    <p>Le vendredi: 8h-12h00 13h30-16h</p>
                </div>
                <div className='button-footer phone'>
                    <Link to="/devis" className='devis' onClick={()=> (window.scrollTo({ top: 0, behavior: 'smooth' }))}>Demander un devis</Link>
                    <Link to="/pdf/Catalogue Produits 2024.pdf" target='_blank' rel='noreferrer' className='catalogue'>Consulter le catalogue 2024</Link>
                </div>
            </div>
            <p className='credits'>Scierie André Charre et fils © 2023 | Développement web : <a href="https://marco-vassal.fr" target='_blank' rel='noreferrer'>Marco Vassal</a> | <Link to="/mentions-legales" onClick={()=> (window.scrollTo({ top: 0}))}>Mention légales</Link></p>
        </div>
    )
}

export default Footer