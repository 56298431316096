import React from 'react'
import Hero from '../Hero'
import History from '../History'

function Home() {
  return (
    <>
        <Hero/>
        <History/>
    </>
  )
}

export default Home