import React from 'react'
import { Link } from 'react-router-dom'

function Realisation() {
    return (
        <div className='realisation'>
            <h1><Link to="/">&#x2B05;</Link>Réalisations</h1>
            <fleche className='fleche-div'>
                <Link to="/" className='fleche'>⬅</Link>
            </fleche>
            <section>
                <div>
                    <div>
                        <h2>Chalet d'une surface de 20m2 avec bardage douglas.</h2>
                    </div>
                    <img src="./images/realisation/chalet.webp" alt="" />
                </div>
                <div>
                    <img src="./images/realisation/piscine (2).webp" alt="" />
                    <div>
                        <h2>Tour de Piscine en Lattes Douglas striées 2.2x11 en 3m</h2>
                    </div>
                </div>
                <div>
                    <div>
                        <h2>Taille de ferme à la demande (ici en Douglas raboté)</h2>
                    </div>
                    <img src="./images/realisation/charpente.webp" alt="" />
                </div>
            </section>
        </div>
    )
}

export default Realisation