import React from 'react'

function Mentions() {
    return (
        <div className='mention'>
            <h1>Mentions Légales</h1>
            <section>
                <h2>1 – Édition du site</h2>
                <p>En vertu de l’article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l’économie numérique, il est précisé aux utilisateurs du site internet <a href="https://www.scierie-charre-ardeche.com">https://www.scierie-charre-ardeche.com</a> l’identité des différents intervenants dans le cadre de sa réalisation et de son suivi :</p>

                <p><strong>Propriétaire du site :</strong> ETS ANDRE CHARRE ET FILS<br />
                    <strong>Contact :</strong> <a href="mailto:scierie.acharreetfils@wanadoo.fr">scierie.acharreetfils@wanadoo.fr</a> | 04 75 94 80 51<br />
                    <strong>Adresse :</strong> 90 chemin des Roquelles, Quartier Les Persèdes, 07170 Lavilledieu.</p>

                <p><strong>Identification de l’entreprise :</strong> SAS ETS ANDRE CHARRE ET FILS<br />
                    <strong>Capital social :</strong> 55 000€<br />
                    <strong>SIREN :</strong> 316186493<br />
                    <strong>Adresse postale :</strong> 90 chemin des Roquelles, Quartier Les Persèdes, 07170 Lavilledieu</p>

                <p><strong>Directeur de la publication :</strong> Marco Vassal<br />
                    <strong>Créateur du site :</strong> Marco Vassal<br />
                    <strong>Contact :</strong> <a href="mailto:marco.vassal07@gmail.com">marco.vassal07@gmail.com</a> | <a href="https://www.marco-vassal.fr">www.marco-vassal.fr</a><br />
                    <strong>Hébergeur :</strong> HOSTINGER operations, UAB – Rue Švitrigailos 34, 03230 Vilnius, Lituanie<br />
                    <strong>Téléphone :</strong> +370 64 50 33 78</p>
            </section>

            <section>
                <h2>2 – Propriété intellectuelle et contrefaçons</h2>
                <p>ETS ANDRE CHARRE ET FILS est propriétaire des droits de propriété intellectuelle et détient les droits d’usage sur tous les éléments accessibles sur le site internet, notamment les textes, images, graphismes, logos, vidéos, architecture, icônes et sons.</p>

                <p>Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des éléments du site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable de ETS ANDRE CHARRE ET FILS.</p>

                <p>Toute exploitation non autorisée du site ou de ln'importe quel éléments qu’il contient sera considérée comme constitutive d’une contrefaçon et poursuivie conformément aux dispositions des articles L.335-2 et suivants du Code de Propriété Intellectuelle.</p>
            </section>

            <section>
                <h2>3 – Limitations de responsabilité</h2>
                <p>ETS ANDRE CHARRE ET FILS ne pourra être tenu pour responsable des dommages directs et indirects causés au matériel de l’utilisateur, lors de l’accès au site <a href="https://www.scierie-charre-ardeche.com">https://www.scierie-charre-ardeche.com</a>.</p>

                <p>ETS ANDRE CHARRE ET FILS décline toute responsabilité quant à l’utilisation qui pourrait être faite des informations et contenus présents sur <a href="https://www.scierie-charre-ardeche.com">https://www.scierie-charre-ardeche.com</a>.</p>

                <p>ETS ANDRE CHARRE ET FILS s’engage à sécuriser au mieux le site <a href="https://www.scierie-charre-ardeche.com">https://www.scierie-charre-ardeche.com</a>, cependant sa responsabilité ne pourra être mise en cause si des données indésirables sont importées et installées sur son site à son insu.</p>

                <p>Des espaces interactifs (espace contact ou commentaires) sont à la disposition des utilisateurs. ETS ANDRE CHARRE ET FILS se réserve le droit de supprimer, sans mise en demeure préalable, tout contenu déposé dans cet espace qui contreviendrait à la législation applicable en France, en particulier aux dispositions relatives à la protection des données.</p>

                <p>Le cas échéant, ETS ANDRE CHARRE ET FILS se réserve également la possibilité de mettre en cause la responsabilité civile et/ou pénale de l’utilisateur, notamment en cas de message à caractère raciste, injurieux, diffamant, ou pornographique, quel que soit le support utilisé (texte, photographie…).</p>
            </section>

            <section>
                <h2>4 – CNIL et gestion des données personnelles</h2>
                <p><strong>Utilisateur :</strong> Internaute se connectant, utilisant le site susnommé : <a href="https://www.scierie-charre-ardeche.com">https://www.scierie-charre-ardeche.com</a>.</p>

                <p>Conformément aux dispositions de la loi 78-17 du 6 janvier 1978 modifiée, l’utilisateur du site <a href="https://www.scierie-charre-ardeche.com">https://www.scierie-charre-ardeche.com</a> dispose d’un droit d’accès, de modification et de suppression des informations collectées.</p>

                <p>Sur le site <a href="https://www.scierie-charre-ardeche.com">https://www.scierie-charre-ardeche.com</a>, le propriétaire du site ne collecte des informations personnelles relatives à l’utilisateur que pour le besoin de certains services proposés par le site <a href="https://www.scierie-charre-ardeche.com">https://www.scierie-charre-ardeche.com</a>. L’utilisateur fournit ces informations en toute connaissance de cause, notamment lorsqu’il procède par lui-même à leur saisie. Il est alors précisé à l’utilisateur du site <a href="https://www.scierie-charre-ardeche.com">https://www.scierie-charre-ardeche.com</a> l’obligation ou non de fournir ces informations.</p>

                <p>Pour plus d’informations sur la façon dont nous traitons vos données (type de données, finalité, destinataire…), contactez-nous à cette adresse mail : <a href="mailto:scierie.acharreetfils@wanadoo.fr">scierie.acharreetfils@wanadoo.fr</a>.</p>
            </section>

            <section>
                <h2>5 – Liens hypertextes et cookies</h2>
                <p>Le site <a href="https://www.scierie-charre-ardeche.com">https://www.scierie-charre-ardeche.com</a> contient des liens hypertextes vers d’autres sites et dégage toute responsabilité à propos de ces liens externes ou des liens créés par d’autres sites vers <a href="https://www.scierie-charre-ardeche.com">https://www.scierie-charre-ardeche.com</a>.</p>

                <p>La navigation sur le site <a href="https://www.scierie-charre-ardeche.com">https://www.scierie-charre-ardeche.com</a> est susceptible de provoquer l’installation de cookie(s) sur l’ordinateur de l’utilisateur.</p>

                <p>Un « cookie » est un fichier de petite taille qui enregistre des informations relatives à la navigation d’un utilisateur sur un site. Les données ainsi obtenues permettent d’obtenir des mesures de fréquentation, par exemple.</p>

                <p>Vous avez la possibilité d’accepter ou de refuser les cookies en modifiant les paramètres de votre navigateur. Aucun cookie ne sera déposé sans votre consentement.</p>

                <p>Les cookies sont enregistrés pour une durée maximale de 13 mois.</p>

                <p>Pour plus d’informations sur la façon dont nous faisons usage des cookies, contactez-nous à cette adresse mail : <a href="mailto:scierie.acharreetfils@wanadoo.fr">scierie.acharreetfils@wanadoo.fr</a>.</p>
            </section>

            <section>
                <h2>6 – Droit applicable et attribution de juridiction</h2>
                <p>Tout litige en relation avec l’utilisation du site <a href="https://www.scierie-charre-ardeche.com">https://www.scierie-charre-ardeche.com</a> est soumis au droit français. En dehors des cas où la loi ne le permet pas, il est fait attribution exclusive de juridiction aux tribunaux compétents de Privas.</p>
            </section>
        </div>
    )
}

export default Mentions