import React from 'react'

function History() {
  return (
    <div className='history'>
      <img src=".\images\histoire\histoire (3).webp" alt="" />
      <p>L’entreprise Scierie André Charre et fils est basée à Lavilledieu en Ardèche 07.
        Notre spécialité est le sciage de résineux, principalement le Douglas, Sapin, Épicéa et Pin Laricio, ainsi que le traitement des bois avec une cuve de traitement agréée, et des produits de seconde transformations avec plusieurs machines de rabotage.</p>
    </div>
  )
}

export default History