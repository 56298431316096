import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

function Navbar() {
    useEffect(() => {
        const navbar = document.getElementsByClassName('navbar')
        const navVisible = document.getElementsByClassName('nav-visible')
        const desktop = document.getElementsByClassName('desktop')
        const phone = document.getElementsByClassName('phone')
        const logo = document.getElementsByClassName('logo-img')
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            if (scrollPosition > 0) {
                logo[0].style.height = '7vh'
                logo[1].style.height = '7vh'
                desktop[0].style.height = '8vh'
                phone[0].style.height = '8vh'
                navbar[0].style.height = '8vh'
                navbar[0].style.boxShadow = '0px 1px 1px black'
                navbar[0].style.alignItems = 'baseline'; // Changement de align-items à baseline
                if (navVisible.length > 0) {
                    navVisible[0].style.paddingTop = '8vh'
                }
            } else {
                logo[0].style.height = '10vh'
                logo[1].style.height = '8vh'
                navbar[0].style.boxShadow = 'none'
                desktop[0].style.height = '12vh'
                phone[0].style.height = '12vh'
                navbar[0].style.height = '12vh';
                navbar[0].style.alignItems = 'center'; // Retour à align-items à center lorsque la position de défilement est à 0
                if (navVisible.length > 0) {
                    navVisible[0].style.paddingTop = '12vh'
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    })
    const [isNavVisible, setNavVisible] = useState(false);

    const toggleNav = () => {
        setNavVisible(!isNavVisible);
    };
    const backToTop = () => {
        window.scrollTo({ top: 0 })
    }
    const backToTopSmooth = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }
    const handleLinkClick = () => {
        toggleNav();
        backToTop();
      };

    return (
        <div className='navbar'>
            <div className='navbar-desktop'>
                <div className='desktop nav-desk'>
                    <div>
                        <Link to="/"><img src="./images/Logo 264x264.png" alt="logo" className='logo-img' onClick={backToTopSmooth}/></Link>
                        <nav>
                            <ul>
                                <li><Link to="/presentation" onClick={backToTop}>Présentation</Link></li>
                                <li id='produits'>
                                    <Link to="/produits" onClick={backToTop}>Produits</Link>
                                    <ul>
                                        <li><Link to="/bois-de-charpente" onClick={backToTop}>Bois de charpente</Link></li>
                                        <li><Link to="/produits-d-amenagement-interieur-et-exterieur" onClick={backToTop}>Produits d'aménagement intérieur et extérieur</Link></li>
                                        <li><Link to="/rabotage-et-traitement" onClick={backToTop}>Rabotage et traitement</Link></li>
                                    </ul>
                                </li>
                                <li><Link to="/realisation" onClick={backToTop}>Réalisations</Link></li>
                                <li><Link to="/contact" onClick={backToTop}>Contact</Link></li>
                                <Link to="/devis" className='devis' onClick={backToTop}>Demander un devis</Link>
                            </ul>
                        </nav>
                    </div>
                </div>

            </div>
            <div className='navbar-phone'>
                <div className='phone'>
                    <Link to="/" onClick={backToTopSmooth}><img src="./images/Logo 264x264.png" alt="logo" className='logo-img' /></Link>
                    <div className={isNavVisible ? 'cross-visible' : ''}>
                        <input type="checkbox" onClick={toggleNav} />

                        <span className='nav-span'></span>
                        <span className='nav-span'></span>
                        <span className='nav-span'></span>
                    </div>
                </div>
                <nav className={isNavVisible ? 'nav-visible' : ''}>
                    <ul id='menu'>
                        <li><Link to="/presentation" onClick={handleLinkClick}>Présentation</Link></li>
                        <li id='produits'>
                            <Link to="/produits" onClick={handleLinkClick}>Produits</Link>
                            <ul>
                                <li><Link to="/bois-de-charpente" onClick={handleLinkClick}>Bois de charpente</Link></li>
                                <li><Link to="/produits-d-amenagement-interieur-et-exterieur" onClick={handleLinkClick}>Produits d'aménagement interieur et exterieur</Link></li>
                                <li><Link to="/rabotage-et-traitement" onClick={handleLinkClick}>Rabotage et traitement</Link></li>
                            </ul>
                        </li>
                        <li><Link to="/realisation" onClick={handleLinkClick}>Réalisations</Link></li>
                        <li><Link to="/contact" onClick={handleLinkClick}>Contact</Link></li>
                    </ul>
                </nav>
            </div>

        </div>

    )
}

export default Navbar