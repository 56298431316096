import React, { useState } from 'react'
import { data } from '../../../data/data';
import { Link } from 'react-router-dom';


function Coffrage() {
    const tableauBody = document.getElementsByClassName('tableau-body')

    const [isTableVisible, setTableVisible] = useState(true);

    const toggleTable = () => {
        if (isTableVisible) {
            tableauBody[0].style.height = '211px'
            tableauBody[0].style.opacity = '1'
            setTimeout(() => {
                tableauBody[0].style.height = 'fit-content'
                const tableauHeight = tableauBody[0].offsetHeight
                tableauBody[0].style.height = tableauHeight + "px"
            }, 300)
        }
        else {
            tableauBody[0].style.opacity = '0'
            tableauBody[0].style.height = '0px'
        }
        setTableVisible(!isTableVisible);
    }
    // Filtrer les produits de la catégorie 'amenagement'
    const amenagementProducts = data
        .filter(product => product.categorie.includes('coffrage'))
        .sort((a, b) => a.name.localeCompare(b.name));
    return (
        <div className='coffrage'>
            <h1><Link to="/bois-de-charpente">&#x2B05;</Link>Planche de coffrage-volige</h1>
            <fleche className='fleche-div'>
                <Link to="/bois-de-charpente" className='fleche'>⬅</Link>
            </fleche>
            <table className='tableau' onClick={toggleTable}>
                <thead>
                    <tr>
                        <td colspan="5"> Tableau de comparaison </td>
                    </tr>
                </thead>
                <tbody className='tableau-body'>
                    <tr>
                        <td>Produit</td>
                        <td>Section (En cm)</td>
                        <td>Longeur (En m)</td>
                        <td>Tarif en Euros TTC au m2 en Douglas</td>
                        <td>Tarif en Euros TTC au m2 en Sapin</td>
                    </tr>

                    <tr>
                        <td>Planche de coffrage</td>
                        <td>2.7xTL</td>
                        <td>2.5 / 3 / 4</td>
                        <td>x</td>
                        <td>7.2</td>
                    </tr>

                    <tr>
                        <td>Planche de Dosse</td>
                        <td>2.7x10</td>
                        <td>3 et 4</td>
                        <td>x</td>
                        <td>7.2</td>
                    </tr>

                    <tr>
                        <td rowSpan={2}>Volige</td>
                        <td>1.5x Toute
                            largeur</td>
                        <td>2 à 4 (Tous
                            les 0.5)</td>
                        <td>8.4</td>
                        <td>6</td>
                    </tr>

                    <tr>
                        <td>1.5 x
                            Largeur fixe</td>
                        <td>2 à 4 (Tous
                            les 0.5)</td>
                        <td>9</td>
                        <td>7.2</td>
                    </tr>
                </tbody>
            </table>
            <div className='cartes'>
                {amenagementProducts.map((product, index) => (
                    <div key={index} className='carte-produit'>
                        <img src={product.imageUrl} alt={product.name} />
                        <div>
                            <h2>{product.name}</h2>
                            <p>Essence : <span>{product.essence}</span></p>
                            <p>Epaisseur : <span>{product.epaisseur}cm</span></p>
                            {product.largeur && product.largeur !== "" && (
                                <p>Largeur : <span>{product.largeur}cm</span></p>
                            )}
                            {product.largeurFixe && product.largeurFixe !== "" && (
                                <p>Largeur fixe : <span>{product.largeurFixe}cm</span></p>
                            )}
                            {product.touteLargeur && product.touteLargeur !== "" && (
                                <p>Toute Largeur : <span>{product.touteLargeur}cm</span></p>
                            )}
                            <p>Longueur : <span>{product.longeur}m</span></p>
                            <p>Qualité/Finition : <span>{product.qualite}</span></p>
                            <p>Prix TTC au m2 : <span>{product.prix}€</span></p>
                            {product.prixSec && product.prixSec !== "" && (
                                <p>Prix TTC au m2 : <span>{product.prixSec}€</span></p>
                            )}
                            <a href={product.fiche} target='_blank' rel="noreferrer" className='devis'>Fiche Technique</a>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Coffrage