import React, { useRef, useState } from 'react';
import emailjs from 'emailjs-com';
import { Link } from 'react-router-dom';

function Contact() {
    const form = useRef();
    const [isSubmitting, setSubmitting] = useState(false);
    const [submissionMessage, setSubmissionMessage] = useState('');

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_qog80rk', 'template_d0zcisr', form.current, 'rrAA4YWLczdVQbVVz')
            .then((result) => {
                setSubmitting(true);
                console.log(result.text);
                setSubmissionMessage('Votre message a bien été envoyé.');
            })
            .catch((error) => {
                setSubmitting(true);
                console.log(error.text);
                setSubmissionMessage('Une erreur s\'est produite. Veuillez réessayer.');
            })
            .finally(() => {
                // Réinitialiser les valeurs des champs du formulaire
                form.current.reset();
                // Afficher le message pendant 2 secondes
                setTimeout(() => {
                    setSubmitting(false);
                    setSubmissionMessage('');
                }, 3000);
            });
    };
    return (
        <div className='contact'>
            <h1><Link to="/">&#x2B05;</Link>Contact</h1>
            <fleche className='fleche-div'>
                <Link to="/" className='fleche'>⬅</Link>
            </fleche>
            <div>
                <div>
                    <h3>Vous pouvez nous retrouver au : <br /> <a target='_blank' rel="noreferrer" href="https://www.google.com/maps/place/André+Charre+et+Fils+SARL/@44.5891721,4.4456317,17z/data=!3m1!4b1!4m15!1m8!3m7!1s0x12b51f0f024897c7:0xb75e53de7abff856!2sAndré+Charre+et+Fils+SARL!8m2!3d44.5891683!4d4.448212!10e1!16s%2Fg%2F1vk4r0sz!3m5!1s0x12b51f0f024897c7:0xb75e53de7abff856!8m2!3d44.5891683!4d4.448212!16s%2Fg%2F1vk4r0sz?entry=ttu">90 chemin des Roquelles, Quartier Les Persèdes, 07170 Lavilledieu</a></h3>
                    <h3><a href="tel:+33475948051">Tél: 04 75 94 80 51</a></h3>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2841.383940070182!2d4.445631712216586!3d44.589172091273724!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12b51f0f024897c7%3A0xb75e53de7abff856!2sAndr%C3%A9%20Charre%20et%20Fils%20SARL!5e0!3m2!1sfr!2sfr!4v1697455492633!5m2!1sfr!2sfr" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <form id="contact-form" ref={form} onSubmit={sendEmail}>
                    <h2>Formulaire de contact</h2>
                    <label htmlFor="user_name">Nom Prénom</label>
                    <input type="text" name="user_name" id="user_name" placeholder='Dupont Jean' required />
                    <label htmlFor="user_email">Email</label>
                    <input type="email" id='user_email' name='user_email' placeholder='exemple@gmail.com' required />
                    <label htmlFor="message">Votre message</label>
                    <textarea type="text" name="message" id="message" placeholder='Bonjour, je souhaiterais avoir des renseignements ...'></textarea>
                    {isSubmitting ? (
                        <p className='submissionMessage'>{submissionMessage}</p>
                    ) : (
                        <input type='submit' className='submit-btn' value="Envoyer" />
                    )}
                </form>
            </div>

        </div>
    )
}

export default Contact
