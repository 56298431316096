import React, { useRef, useState } from 'react';
import emailjs from 'emailjs-com';
import { Link } from 'react-router-dom';

function Devis() {
    const form = useRef();
    const [isSubmitting, setSubmitting] = useState(false);
    const [submissionMessage, setSubmissionMessage] = useState('');

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_qog80rk', 'template_0oheyaa', form.current, 'rrAA4YWLczdVQbVVz')
            .then((result) => {
                setSubmitting(true);
                console.log(result.text);
                setSubmissionMessage('Votre message a bien été envoyé.');
            })
            .catch((error) => {
                setSubmitting(true);
                console.log(error.text);
                setSubmissionMessage('Une erreur s\'est produite. Veuillez réessayer.');
            })
            .finally(() => {
                // Réinitialiser les valeurs des champs du formulaire
                form.current.reset();
                // Afficher le message pendant 2 secondes
                setTimeout(() => {
                    setSubmitting(false);
                    setSubmissionMessage('');
                }, 3000);
            });
    };
    return (
    <div className='page-devis'>
        <h1><Link to="/">&#x2B05;</Link>Devis</h1>
        <fleche className='fleche-div'>
                <Link to="/" className='fleche'>⬅</Link>
            </fleche>
        <form id="contact-form" ref={form} onSubmit={sendEmail}>
            <label htmlFor="user_name">Nom Prénom</label>
            <input type="text" name="user_name" id="user_name" placeholder='Dupont Jean' required />
            <label htmlFor="user_adresse">Adresse</label>
            <input type="text" name="user_adresse" id="user_adresse" placeholder='90 chemin des Roquelles, Quartier Les Persèdes' required />
            <label htmlFor="user_codepostale">Code postal</label>
            <input type="text" name="user_codepostale" id="user_codepostale" placeholder='07170' required />
            <label htmlFor="user_commune">Commune</label>
            <input type="text" name="user_commune" id="user_commune" placeholder='Lavilledieu' required />
            <label htmlFor="user_email">Email</label>
            <input type="email" id='user_email' name='user_email' placeholder='exemple@gmail.com' required/>
            <label htmlFor="user_phone">Téléphone</label>
            <input type="tel" id='user_phone' name='user_phone' placeholder='06 11 22 33 44' required/>
            <label htmlFor="projet">Votre projet</label>
            <textarea type="text" name="projet" id="projet" placeholder='Votre projet...' required></textarea>
            {isSubmitting ? (
                    <p className='submissionMessage'>{submissionMessage}</p>
                ) : (
                    <input type='submit' className='submit-btn' value="Envoyer"/>
                )}
        </form>
    </div>
  )
}

export default Devis