import React from 'react'
import { Link } from 'react-router-dom'

function Produit() {
  const backToTop = () => {
    window.scrollTo({ top: 0 })
}
  return (
    <div className='produits'>
        <h1><Link to="/">&#x2B05;</Link>Produits</h1>
        <fleche className='fleche-div'>
                <Link to="/" className='fleche'>⬅</Link>
            </fleche>
        <div>
            <Link to='/bois-de-charpente' onClick={backToTop}><h2>Bois de Charpente</h2></Link>
            <Link to='/produits-d-amenagement-interieur-et-exterieur' onClick={backToTop}><h2>Produits d'aménagement interieur et exterieur</h2></Link>
            <Link to='/rabotage-et-traitement' onClick={backToTop}><h2>Rabotage et traitement</h2></Link>
        </div>
    </div>
  )
}

export default Produit